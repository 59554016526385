import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import { TFunction } from 'i18next'
import { withTranslation } from 'react-i18next'
import GateLayout from '../layouts/gateLayout'
import { styles } from '../pagesStyles/gateStyles'
import ContentContainer from './../components/contentContainer'
import BlockUserInterface from './../components/blockUserInterface'
import LoginComponent from '../components/signIn'

interface JoinProps {
  t: TFunction
}

const GatePage = ({ t }: JoinProps) => {
  const [isPageLoading, setIsPageLoading] = useState(false)
  const classes = styles()

  if (isPageLoading) {
    return <BlockUserInterface />
  }

  return (
    <>
      {!isPageLoading && (
        <GateLayout>
          <ContentContainer>
            <div className={classes.headingTextContainer}>
              <Typography
                variant="h1"
                align="center"
                className={classes.headingText}
              >
                {t('gate.coming2021')}
              </Typography>
              <Typography
                variant="h1"
                align="center"
                className={classes.headingText}
              >
                {t('gate.nowCharterMembersOnly')}
              </Typography>
            </div>
            <LoginComponent
              setIsLoggedIn={setIsPageLoading}
              fromGatePage={true}
            />
          </ContentContainer>
        </GateLayout>
      )}
    </>
  )
}

export default withTranslation()(GatePage)
