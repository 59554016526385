import { makeStyles } from '@material-ui/core/styles'
import { COLORS, FONTS, BREAKPOINTS } from '../../constants'

export const styles = makeStyles(theme => ({
  header: {
    width: '100%',
  },
  listItem: {
    display: 'block',
    fontFamily: FONTS.Graphik.GraphikRegular,
    fontSize: '0.875rem',
    textDecoration: 'none',
    background: 'transparent',
    border: 'none',
    color: theme.palette.common.black,
    textTransform: 'uppercase',
    letterSpacing: '0.88px',
    cursor: 'pointer',
    textAlign: 'center',
    padding: 0,
    outline: 'none',
    width: 'max-content',
  },
  sloganBanner: {
    height: 40,
    backgroundColor: COLORS.whiteStain,
    padding: '8px 0',
  },
  sloganTitle: {
    fontFamily: FONTS.Schnyder.SchnyderXLLight,
    fontSize: '1.25rem' /*equals 20px */,
    margin: 0,
    textAlign: 'center',
    textTransform: 'lowercase',
  },
  navigation: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '30px 0',
  },
  chirpyestLogoLink: {
    display: 'block',
    height: 35,
    width: 35,
  },
  chirpyestLogo: {
    maxWidth: '100%',
    maxHeight: '100%',
    position: 'relative',
  },
  navLeft: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'flex-start',
    width: '90%',
    [`@media (max-width: ${BREAKPOINTS.lg}px)`]: {
      justifyContent: 'flex-end',
      width: '90%',
    },
  },
  navCenter: {
    display: 'flex',
  },
  /*center nav items */
  chirpyestTextLogo: {
    height: 60,
    [`@media (max-width: ${BREAKPOINTS.lg}px)`]: {
      height: 47,
    },
  },
}))
