import * as React from 'react'
import CHRBanner from '../components/header/Banner'
import CHRHeader from './../components/gateHeader'
import CHRFooter from './../components/footer'
import { title } from '../constants'
import { useStyles } from './styles'
import { ThemeProvider } from '@material-ui/styles'
import theme from './../gatsby-theme-material-ui-top-layout/theme'
import CssBaseline from '@material-ui/core/CssBaseline'
import i18n from './../components/i18n'
import { I18nextProvider } from 'react-i18next'
import Amplify from '@aws-amplify/core'
import { Auth } from '@aws-amplify/auth'
import awsConfig from './../aws-exports'
import SEO from './../components/seo'
import { useAppState } from '../appState'

Amplify.configure(awsConfig)
Auth.configure(awsConfig)

interface DefaultLayoutProps extends React.HTMLProps<HTMLDivElement> {
  children: React.ReactNode
}

const GateLayout = (props: DefaultLayoutProps) => {
  const { children } = props
  const classes = useStyles({})
  const [appState] = useAppState()
  const isLoggedIn = Boolean(appState.userId)
  return (
    <div>
      <SEO
        title={'Shop and Earn cash back'}
        description={
          'Shop 1000+ stores. Share style finds. Make money when you or friends buy from your personal boutique. Fashion, home, beauty, travel, lifestyle.'
        }
      />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className={classes.pageWrapper}>
          <CHRBanner isLoggedIn={isLoggedIn} />
          <CHRHeader />
          <I18nextProvider i18n={i18n}>
            <main className={classes.mainContainer}>{children}</main>
          </I18nextProvider>
          <CHRFooter title={title} />
        </div>
      </ThemeProvider>
    </div>
  )
}

export default GateLayout
