import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles(theme => ({
  headingTextContainer: {
    margin: 'auto',
    padding: 20,
    paddingTop: 80,
    paddingBottom: 0,
  },
  headingText: {
    marginBottom: 0,
  },
}))
